export class Drag<T> {
    private dragPosition = 0

    constructor(public readonly arr: T[]) {
    }

    onDragStart(ev: DragEvent, i: number) {
        console.log('onDragStart',ev, i)
        ev.stopPropagation()
        this.dragPosition = i
        setTimeout(() => (ev.target as HTMLImageElement).classList.add('moving'), 0)
    }

    onDragEnter(ev: DragEvent, i: number) {
        // console.log('onDragEnter', i, this.arr)
        ev.preventDefault()
        if (!this.arr) return
        if (this.dragPosition != i) {
            const s = this.arr[this.dragPosition]
            const t = this.arr[i]
            this.arr.splice(i, 1, s)
            this.arr.splice(this.dragPosition, 1, t)
            this.dragPosition = i
        }
    }

    onDragEnd(ev: DragEvent) {
        (ev.target as HTMLImageElement).classList.remove('moving')
        // console.log('onDragEnd', ev)
    }

    onDragOver(ev: DragEvent) {
        // console.log('onDragOver', ev)
        ev.preventDefault()
        if (ev.dataTransfer) ev.dataTransfer.dropEffect = 'move'
    }
}