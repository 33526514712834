import {IActivityGift} from "@/app/editor/material/gift";
import {Module} from "@/app/editor/module";
import {DefineComponent, UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";
import ForwardPreview from "@/app/editor/module/forward/v2/ForwardPreview.vue";
import ForwardSetupView from "@/app/editor/module/forward/v2/ForwardSetupView.vue";

export interface IForwardRule {
    condition: string
    img_url: string | null
    title: string
    value: number
    gift: IActivityGift | null
}

export interface IForwardConfig {
    rules: IForwardRule[]
}

export class Forward extends Module {
    cfg: UnwrapRef<IForwardConfig>;

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '转发奖励', 'fen_xiang', ForwardPreview as DefineComponent, ForwardSetupView as DefineComponent);
        this.cfg = m.config as IForwardConfig
        // console.log(this.cfg.rules)
    }
}